import React, {Fragment, useState, useEffect, useContext} from "react"
import {Link, useHistory} from "react-router-dom"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../../services/CommonService"
import useModal from "../../../../../hooks/useModal"
import {FormFieldTable, RenderData} from "../../../../Common/FormFieldsView"
import ActivityService from "../../Activities/ActivityService"
import useStyle from '../../../../../hooks/useStyle'
import NutritionCareNotification from '../../../../NutritionCares/NutritionCareNotification'
import GenericModal from "../../../../../components/Modals/GenericModal"
import ActivityForm from '../../../MotherInfantJourney/Activities/Form/ActivityForm'
import CTLazyLoad from "../../../../Common/CTLazyLoad"
import { LinkFontAwesomeIcon } from "../../../../Common/ImageIcons"
import useSort from '../../../../../hooks/useSorting'
import SortDirection from '../../../../../components/Common/SortDirection'
import {FontAwesomeBtn} from "../../../../Common/Button"
import {OptionsObjList} from "../../../../Common/FormInput"
import { CTTooltip, CueTip } from "../../../../Common/CueTooltip"
import {DraftBadge} from "../../../../Common/NotificationPill"
import {ActivityCard, AccessBFCard, BFPanelList} from "./ActivityCard"
import PaginationSM from '../../../../Common/PaginationSM'
import {EmptyRowAlert} from '../../../../Common/TableView'
import {usePageLocale} from "./../../../../../hooks/useLocale"
import {useAccessBfHook} from "./useActivityHook"
import {UserName} from '../../../../UserProfile/UserInfoPopup'

const css = ['/css/tile_components.css'];
let requestParams = {};
let selectedItem = null;
let actFormProps = null;
const AssessBFTable = (props) =>{
  const {state: { user: currentUser }} = useContext(AuthContext);

  const {journeyProfile, searchString, componentName, dataSource, timeFilter} = props;
  const history = useHistory();

  const [child, setChild] = useState();
  const [loading, setLoading] = useState(true);
  const [isTableView, setTableView] = useState(true);
  const [activities, setActivities] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState({});
  const [formFields, setFormFields] = useState();
  const [formFieldsGrouped, setFormFieldsGrouped] = useState();
  const [accessBfReport, setAccessBFReport] = useState({});
  const [filter, setFilter] = useState(timeFilter?.options[0]);
  const [formFieldAlerts, setFormFieldAlerts] = useState({});
  const [counselingIds, setCounselingIds] = useState([]);

  const { isOpen:isAssessMenuOpen, toggleModal: toggleAssessMenuModal } = useModal();
  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal();
  const { isOpen:isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal();
  const { isOpen:isActivityCardOpen, toggleModal: toggleActivityCardModal } = useModal();

  const {labelLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage');
  const {labelLocale:tableHeadLocale, getObjLocale} = usePageLocale(currentUser.current_locale, 'commonTableHeaders');
  const {labelLocale:accessBFTableLocale} = usePageLocale(currentUser.current_locale, 'accessBFTable');

  const { groupAccessBFReport, checkAlertMessage } = useAccessBfHook(currentUser.current_locale, accessBFTableLocale);

  useEffect(() => {
    requestParams = {
      'sort_column': 'scheduled_date',
      'sort_direction': 'desc',
      'journey_profile_id': journeyProfile.id,
      'search': searchString,
      'page': currentpage,
      'per_page': 15,
      'activity_form_id': dataSource.child_form,
      'activity_type': 'assess_bf', //dataSource.sid,
      'filter': filter,
      'form_rules_params':{
        'form_template_id': dataSource.child_form,
        'default_fields': 'ignore',
        'fields': ['field_client_id', 'field_index', 'form_template_id', 'form_rule_alerts']   
      },
      'form_fields_params': {
        'ids':[dataSource.child_form],
        'locale': currentUser.current_locale
      }
    }
      getActivityList()
  }, [searchString, currentpage, filter])

  let getActivityList = () => {
    setLoading(true);
    if(formFields) delete requestParams.form_fields_params;
    
    ActivityService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        setActivities(res.data.activities);
        setChild(res.data.child);
        setPagemeta(res.data.meta);
        /*const access_bf = Cs.groupBy(res.data.access_bf_reports, 'value')
        setAccessBFReport({
          ...access_bf,
          favorable_count:access_bf[1]?.reduce((acc, val) => acc + parseInt(val.count), 0),
          unfavorable_count:access_bf[1]?.reduce((acc, val) => acc + parseInt(val.count), 0)
        })*/
        if(!formFields){
          setFormFields(res.data.form_fields[dataSource.child_form]);
          setFormFieldsGrouped(Cs.listToObj(res.data.form_fields[dataSource.child_form], 'client_id'));
        }
        if(res.data.form_rules){
          setFormFieldAlerts(Cs.groupBy(res.data.form_rules, 'field_client_id'));
        }
        setLoading(false);
      }
    })
  }
  
  const {sort} = useSort(requestParams, getActivityList);

  const onRangeFilterChange = (selectedIndex) =>{
    const f = timeFilter?.options[selectedIndex];
    setFilter({
      'label':f.label,
      'per_page':15,
      'date_type':f.sid
    })
  } 

  const routeAssess = (id, schedule_id) =>{
    actFormProps = Cs.routeInfantPath(dataSource, {}, journeyProfile, history, null, id);
    actFormProps.match.params.schedule_id = schedule_id;
    actFormProps.component = ActivityForm;

    if(child){
      let c = child;
      c.data.baby_age = Cs.getAge(c.data.dob_baby_formatted_date);
      actFormProps.location.state = {
        'child_fk_id': c.record_id, 
        'dob_baby_formatted_date': c.data.dob_baby_formatted_date, 
        'id_mother': journeyProfile.data.cell_number, 
        'baby_age': c.data.baby_age
      };
    }

    toggleAssessMenuModal();
    toggleInfantActivityModal();
  }

  const onFormSubmit = (activity={}) =>{
    if(activity.show_nutrition_care){
      openNotification(null, activity);
    }
    getActivityList();
    toggleInfantActivityModal();
  }

  const openNotification = (e, activity) =>{
    e && e.stopPropagation();
    setCounselingIds([]);
    selectedItem = activity;
    toggleNutritionCareModal();
  }

  const openActivityCard = (activity) =>{
    selectedItem = activity;
    toggleActivityCardModal();
  }

  const openCounselingAlert = (alerts) =>{
    selectedItem = null;
    const counselingIds = alerts.filter(a => a.form_rule_alerts?.length > 0).map(a => a.form_rule_alerts)?.flat()?.map(c => c?.counseling_id);
    if(counselingIds.length > 0){
      setCounselingIds(counselingIds);
      toggleNutritionCareModal();
    }
  }

  /*const setAlertMessage = (activity) =>{
    if(activity.id){
     return ''
    }else if(Cs.compareDate(activity.scheduled_date, null, 'days') >= 0){
      return activity.notification_text || 'A visit to take new measurements is scheduled for this date'
    }else{
      return 'Measurement data from this visit was not submitted.'
    }
  }*/  

  const ActivityList = () => activities && activities.map((a, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4 p-5" key={i}>
      <AccessBFCard activity={a} formFields={formFields} index={i}
        openNotification={openNotification} routeAssess={routeAssess}
        groupAccessBFReport={groupAccessBFReport} labelLocale={labelLocale}
        checkAlertMessage={checkAlertMessage} openActivityCard={openActivityCard}
        currentUser={currentUser}/>
    </div>
  ))

  if(isActivityCardOpen){
    return (
      <CTLazyLoad css={css}>
        <GenericModal 
          component={AccessBFCard} 
          isOpen={isActivityCardOpen}
          toggleModal={toggleActivityCardModal}
          activity={selectedItem}
          formFields={formFields} 
          openNotification={openNotification} 
          routeAssess={routeAssess}
          groupAccessBFReport={groupAccessBFReport} 
          labelLocale={labelLocale}
          checkAlertMessage={checkAlertMessage}
          openActivityCard={openActivityCard}
          caseId={journeyProfile?.record_id}
          caseName={journeyProfile?.data?.mother_name}
          childName={child?.data?.baby_name}
          child={child}
          showWhatsapp={true}
          currentUser={currentUser}/>
      </CTLazyLoad>
    )
  }

  return(
    <CTLazyLoad css={css}>
      <FontAwesomeBtn id="bf_add_btn" tooltip="Record details in a new entry" 
        iconCss="fa fas fa-plus" isResponsive={false} name={labelLocale(3)} label="Add"
        css="pull-right m-b-5 m-r-15 lh-17" colorCss="btn-green"
        onSelect={()=>routeAssess()} tooltip={labelLocale(14)}>
      </FontAwesomeBtn>

      <FontAwesomeBtn id="bf_toggle_table" tooltip={labelLocale(15)} 
        iconCss="fa fa-table" isResponsive={false} name={tableHeadLocale(5)} label="Table" 
        css="pull-right m-b-5 m-r-15 lh-17" colorCss="bg-highlight"
        onSelect={()=>setTableView(!isTableView)}>
      </FontAwesomeBtn>
      
      <span id="bf_date_filter" className="tooltip pull-right m-r-15">  
        <select onChange={(e)=>onRangeFilterChange(e.target.selectedIndex)}
          className="form-control ht-33 fw500 mxw-150"
          defaultValue={filter.label}>
          <OptionsObjList list={timeFilter?.options} idKey="label" labelKey="label" userLocale={currentUser.current_locale}/>
        </select>
        <CueTip tooltip={labelLocale(12)} positionCss="bottom"/>
      </span>
        
      <div className="col-xs-12 table-responsive table-fix-head">
        {loading ? 
          <div className="spinner"/>
          :
          <>    
            {isTableView ?
              <table className="table">
                <thead>
                  <tr>
                    <th className='text-center' label="ID">
                      {tableHeadLocale(0)} 
                    </th>
                    <th className='text-center' label="Date">
                      {getObjLocale(formFieldsGrouped.record_date)}
                    </th>
                    <th className='text-center' label="As in Tutorial">
                      {accessBFTableLocale(4)}
                    </th>
                    <th className='text-center' label="Needs Tutorial">
                      {accessBFTableLocale(5)}
                    </th>
                    <th className='text-center' label="Notification">
                      {accessBFTableLocale(6)}
                    </th>
                    <th className='text-center' label="By">
                      {tableHeadLocale(2)}
                    </th>
                    <th className='text-center' label="Actions">
                      {tableHeadLocale(3)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <AccessBFTableData activities={activities} 
                  routeAssess={routeAssess} labelLocale={labelLocale}
                  openNotification={openNotification}
                  checkAlertMessage={checkAlertMessage}
                  groupAccessBFReport={groupAccessBFReport}
                  formFields={formFields}
                  openActivityCard={openActivityCard}
                  formFieldAlerts={formFieldAlerts}
                  openCounselingAlert={openCounselingAlert}
                  accessBFTableLocale={accessBFTableLocale}/>
                </tbody>
              </table>
              :
              <ActivityList/>
            }

            <PaginationSM
              className="pagination-bar"
              currentPage={currentpage}
              totalItems={pagemeta.total}
              itemsPerPage={pagemeta.per_page}
              onPageChange={setCurrentpage}
              siblingCount={2}/>

            {activities.length === 0 && <EmptyRowAlert/>}
        
            {isNutritionCareOpen && 
              <GenericModal component={NutritionCareNotification} 
                toggleModal={toggleNutritionCareModal} 
                isOpen={isNutritionCareOpen}
                itemType="infant.activity" 
                itemUuid={selectedItem?.id}
                counselingIds={counselingIds}
                journeyProfileId={journeyProfile.id}
                mobileNo={journeyProfile.data.cell_number}
                countryCode={journeyProfile.data.country_code}/>
            } 

            {isInfantActivityFormOpen && 
              <GenericModal toggleModal={toggleInfantActivityModal} 
              isPopupMode="true" onFormSubmit={onFormSubmit} 
              closeConfirmPopup={true} {...actFormProps} />
            }
          </>
        }
      </div>
    </CTLazyLoad>
  )
}

const AccessBFTableData = ({labelLocale, formFieldAlerts, openCounselingAlert, activities, openActivityCard, routeAssess, openNotification, checkAlertMessage, groupAccessBFReport, formFields, accessBFTableLocale}) => activities.map((activity, k) => {
  const {data, data_source} = activity;
  const msg = checkAlertMessage(activity);
  const accessBfReport = groupAccessBFReport(formFields, data_source);

  return(
    <tr key={k}>
      <td className="text-center" onClick={()=>openActivityCard(activity)}>
        {activity.record_id}
        <DraftBadge isDraft={activity.draft}/>
      </td>

      <td className="text-center">
        {Cs.formatUpdateDate(activity.id? activity.data.record_date : activity.scheduled_date)}
      </td>
        
      <td className="text-center">
        <div className="mxh-250 scroll-y">
          <BFPanelList 
            isPillView={true}
            className="card-bg-green" 
            list={accessBfReport.pos} 
            formFields={formFields}/>
        </div>
      </td>

      <td className="text-center">
        <div className="mxh-250 scroll-y">
          <BFPanelList 
            isPillView={true}
            className="badge-danger" 
            list={accessBfReport.neg} 
            formFields={formFields}
            formFieldAlerts={formFieldAlerts}
            openCounselingAlert={openCounselingAlert}/>
        </div>
      </td>

      <td className="text-center">
        <div className="card-footer flex justspacebetween p20 aligncenter mih-72">
          <ul className="flex aligncenter">
            {activity.nutrition_care_alert_count > 0 &&
              <li className="m-r-20">
                <LinkFontAwesomeIcon 
                  tooltip="Notifications"
                  iconCss="fas fa-envelope white"
                  onSelect={(e)=>openNotification(e, activity)} />
              </li>
            }
            {/*count={activity.nutrition_care_alert_count}*/}
          </ul>
        </div> 
      </td>
      
      <td className="text-center">
        <UserName id={activity.created_by} role={activity?.member_role?.role}>{activity.author_name}</UserName>
        <div>{activity?.member_role?.role}</div>
      </td>

      <td className="text-center mxw-150">
        {activity.id ?
          <span onClick={e=>routeAssess(activity.id, activity.schedule_id)} className="fas fa-edit m-r-15 font-18 text-muted" />
          :
          <>
            {Cs.compareDate(activity.scheduled_date, null, 'days', false, true) <= 0 &&
              <div className="text-center p-t-15p pointer">
                <button onClick={e=>routeAssess(activity.id, activity.schedule_id)}
                  type="button" className="btn btn-icon-add btn-sm text-white m-b-5"
                  label="Add">
                    {labelLocale(3)}
                </button>
              </div>
            }
            <div className="text-center m-t-15">  
              {msg}
            </div>
          </>
        }
      </td>
    </tr>
  )
})

export default AssessBFTable;